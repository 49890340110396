<template>
  <div>
    <v-sheet>
      <v-card>
        <v-card-title>Турнирная таблица</v-card-title>
      </v-card>
      <v-simple-table class="mе-4 mb-4 ml-0 mr-0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left">Команда</th>
              <th class="text-left">Раунд 1</th>
              <th class="text-left">Раунд 2</th>
              <th class="text-left">Раунд 3</th>
              <th class="text-left">Раунд 4</th>
              <th class="text-left">Баллов</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in teams" :key="item.id">
              <td>{{ item.index }}</td>
              <td style="text-transform: capitalize">{{ item.id }}</td>
              <td>{{ item.kahoot }}</td>
              <td>{{ Math.round(item.team) }}</td>
              <td>{{ item.advert }}</td>
              <td>{{ Math.round(item.personal) }}</td>
              <td>{{ Math.round(item.total) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'NoLimitsStandings',
  props: ['items', 'my-points', 'my-game-id', 'params'],
  computed: {
    ...mapGetters({
      meta: 'event/eventMeta',
    }),
    teams() {
      const teamsByIDs = _.reduce(
        this.items,
        (acc, item) => {
          const { tags } = item;
          const teamTag = _.find(tags, (tag) => /команда\s+\d+/gi.test(tag));
          if (teamTag) {
            if (acc[teamTag] == null) {
              acc[teamTag] = {
                items: [],
                quest: 0,
              };
            }
            acc[teamTag].items.push(item);
            acc[teamTag].quest += item.points;
          }
          return acc;
        },
        {}
      );
      const result = _.map(
        _.orderBy(
          Object.keys(teamsByIDs).map((teamKey) => {
            const { items: teamPlayers } = teamsByIDs[teamKey];
            const sortedPlayers = _.orderBy(
              teamPlayers,
              ['personal'],
              ['desc']
            );
            const countablePlayers =
              sortedPlayers.length > 1
                ? _.slice(sortedPlayers, 0, 3)
                : sortedPlayers;
            const totalCountableQuestPoints = countablePlayers.reduce(
              (acc, player) => {
                return acc + player.personal;
              },
              0
            );
            const teamPoints = sortedPlayers.reduce((acc, item) => {
              return acc + item.team;
            }, 0);
            const personal = totalCountableQuestPoints;
            const kahoot = this.kahoot[teamKey] || 0;
            const advert = this.advert[teamKey] || 0;
            const team = teamPoints || 0;
            const result = {
              id: teamKey,
              kahoot, // раунд 1 - Kahoot
              team, // раунд 2 - командный
              advert, // раунд 3 - шедевры рекламы
              personal, // раунд 4 - Личные задания в Ё
              total: kahoot + team + advert + personal, // Сумма всех раундов
            };
            return result;
          }),
          ['total'],
          ['desc']
        ),
        (item, index) => {
          return {
            ...item,
            index: index + 1,
          };
        }
      );
      return result;
    },
    kahoot() {
      return (this.meta && this.meta.kahoot) || {};
    },
    advert() {
      return (this.meta && this.meta.advert) || {};
    },
  },
};
</script>
