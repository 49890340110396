<template>
  <app-event event-id="glowbyte-no-limits" auth="login-only" :mapping="mapping">
    <template v-slot:logo>
      <div
        style="
          padding: 4px;
          border-radius: 8px;
          background-color: white;
          margin: 16px;
        "
      >
        <v-img :src="require('./assets/logo.png')" class="ma-4" />
      </div>
    </template>
  </app-event>
</template>

<script>
import AppEvent from '@/components/event/Event.vue';
import Quest from './Quest';

export default {
  name: 'NoLimitsPageEvent',
  components: {
    AppEvent,
  },
  data() {
    return {
      mapping: {
        quest: Quest,
      },
    };
  },
};

/**

    0 раунд
    1) ЦУ
    2) Фото на паспорт

    1 раунд
    Ё-КВИЗ
    - онлайн игра соло
    - игроки подключаются через QR-код со своих гаджетов
    - вопросы вытащить из кахута
    - ответственный - Борзунов

    2 раунд
    КОМАНДНЫЕ

    1)  Полиглот, но с песнями 
    12 песен
    даём список из 13 языков
    указать неверный

    Казахский
    Немецкий
    Голландский
    Индийский
    Хорватский
    Молдавский
    Армянский
    Итальянский
    Греческий
    Английский
    Испанский
    Французский
    Японский

    Подсказка:
    Верного ответа нет среди первых трёх, и последних трёх вариантов

    https://disk.yandex.ru/d/0EWE3XnWAy1FcA

    2) Спортивные карты мира

    https://disk.yandex.ru/d/0lHv9vkVC_gL4Q

    3) QR-киномания: тема необычного путешествия

    https://disk.yandex.ru/d/mGo9DgtA6HragA

    3 раунд
    ГОРОДА в общем зале

    4 раунд
    Личные

    1) Традиции
    2) Кухня
    3) Раскраска
    4) Поле чудес
    5) Ребус
    6) Радуга (флаги)
 */
</script>
